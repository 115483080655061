import React from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Link, useNavigate, useParams } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { useEffect, useState } from 'react';
import { getDatabase, ref, onValue, get, set, push, update, serverTimestamp } from 'firebase/database';
import { getBookingByID, sendEmail, sendSMS, updateBookingStatus, checkCoupon, getInquiryDataById, getPlatformPercentages, getCurrentUserDetails, getUserByID, BookingStatusUpdateEmailBody, getOwnerMeta, getUserIdByRefferralCode, getListingByID } from './utils';
import dayjs from 'dayjs';
import { useAuth } from '../contexts/AuthContext';
import TextField from '@mui/material/TextField';
import { Alert, Checkbox, FormControlLabel } from '@mui/material';
import { Col, Row, Button } from 'react-bootstrap';
import { ThreeDots } from 'react-loader-spinner';


const PaymentForm = ({ amount }) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL; // Fetch the base URL from .env
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate(); // Initialize useNavigate
  const auth = useAuth();


  const [storedBookingId, setBookingId] = useState('');
  const [storedPaymentId, setPaymentId] = useState('');
  const [bookingData, setBookingData] = useState('');
  const [acknowledgement2, setAcknowledgement2] = useState(false);
  const [offer, setOfferData] = useState('');
  const [inquiryData, setInquiryData] = useState([]);

  const [totalTax, setTotalTax] = useState(0);
  const [adminTax, setAdminTax] = useState(0);
  const [amountTotal, setAmountTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const [coupon, setCouponCode] = useState('');

  const [discount, setDiscount] = useState(0);
  const [totalOwnerBookings, setTotalOwnerBookings] = useState(0);


  const queryParameters = new URLSearchParams(window.location.search)
  const bookingId = queryParameters.get("bookingId");
  const [platformPercentages, setPlatformPercentages] = useState({});

  const handleAcknowledgement = (acknowledgementName) => {
    if (acknowledgementName === 'acknowledgement1') {
    } else if (acknowledgementName === 'acknowledgement2') {
      setAcknowledgement2((prev) => !prev);
    }
  };

  const [listingData, setListingData] = useState([]);
  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const bookingDetails = await getBookingByID(bookingId);
        if (!bookingDetails) {
          // No need to proceed if bookingDetails is null
          return;
        }

        const listingDetails = await getListingByID(bookingDetails.listingId);
        setListingData(listingDetails);
        
        const inquiryDetails = await getInquiryDataById(bookingDetails['inquiryId']);
        const ownerMetaDetails = await getOwnerMeta(bookingDetails.owner);
        
        if (ownerMetaDetails?.totalPaidBookings != null) {
          setTotalOwnerBookings(ownerMetaDetails.totalPaidBookings); // Corrected function name
        } else {
          setTotalOwnerBookings(0);
        }
        
        // Logging the updated value of totalOwnerBookings here
        
        setInquiryData(inquiryDetails);
        const offerDetails = inquiryDetails['offerData'];
    
        const platformFeesData = await getPlatformPercentages();
    
        let platformData = {
          salesTax: parseFloat(platformFeesData.defaultSalesTax),     
        }
        platformData.adminCut = parseFloat(platformFeesData.defaultAdminCut);
        // if(listingDetails.boatType === 'Bass Boat') {
        //   platformData.adminCut = parseFloat(platformFeesData.defaultAdminCut);
        //   console.log('bass boat');
        // } else {
        //   platformData.adminCut = parseFloat(platformFeesData.defaultOtherBoatTypeAdminCut);
        //   console.log('other type');
        // }
    
        setPlatformPercentages(platformData);
        setOfferData(offerDetails);
        setBookingId(bookingId);
        setBookingData(bookingDetails);
        setPaymentId(bookingDetails.paymentId);
      } catch (error) {
        console.error('Error fetching listing details:', error);
      }
    };
  
    fetchDetails();
  }, [bookingId]); // Ensure to include bookingId in the dependency array

  const verifyDiscount = async (code) => {
    const coupon = await checkCoupon(code);
    if(coupon !=null) {
      setDiscount(parseInt((coupon[0].discount)*100));
    }
  }

  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    if(!acknowledgement2) {
      setError('You must acknowledge to continue.');
      setLoading(false);
    };
  
    if (!stripe || !elements) {
      return;
    }
  
    const { token, error } = await stripe.createToken(elements.getElement(CardElement));
  
    if (error) {
      console.error(error);
      setError(error.message || "There was an error processing your card. Please try again.");
      setLoading(false); // Don't forget to set loading to false here as well
      return;
    } else {

      const taxTotal = (platformPercentages.salesTax * amount);
      setTotalTax(taxTotal);
      const taxAdmin = (platformPercentages.adminCut * taxTotal);
      setAdminTax(taxAdmin);

      let cutAdmin = 0;
      let refferalAmount = 0;
      let adminFirstTimeCut = 0;
      let cutPercentage = 0.10;
      const ownerData = await getUserByID(bookingData.owner);
      const referId = await getUserIdByRefferralCode(ownerData.refferalCode);
      // if(totalOwnerBookings < 5) {
      //   if (differenceInDays > 14) {
      //     cutAdmin = (platformPercentages.adminCut * 20000);
      //   } else {
      //     cutAdmin = (platformPercentages.adminCut * amount);
      //   }
      //   cutPercentage = platformPercentages.adminCut;
      // } else if (totalOwnerBookings < 10) {
      //   if (differenceInDays > 14) {
      //     cutAdmin = (0.15 * 20000);
      //   } else {
      //     cutAdmin = (0.15 * amount);
      //   }
      //     cutPercentage = 0.15;
      // } 
      if (totalOwnerBookings === 0){
        if(referId !== null) {
          const database = getDatabase();
          if (differenceInDays > 14) {
            cutAdmin = (0.05 * amount) + (platformPercentages.adminCut * 20000)+4000;
          } else {
            cutAdmin = (0.05 * amount) + (platformPercentages.adminCut * amount)+4000; // 10000 = $100
          }
          adminFirstTimeCut = 4000;
          const referPayments = ref(database, `ownerMeta/${referId}`)

          const previousReferPaymentsSnapshot = await get(referPayments);

          if (previousReferPaymentsSnapshot.exists()) {

            const referPaymentData = previousReferPaymentsSnapshot.val();

            const previousCurrentAmount = referPaymentData.payments.currentAmount;
            const previousTotalAmount = referPaymentData.payments.totalAmount;
            const referPaymentObject = {
              currentAmount: previousCurrentAmount + 3000,
              totalAmount: previousTotalAmount + 3000,
            }
            await update(referPayments, {payments: referPaymentObject});
            refferalAmount = 3000;
          }
        }
        else {
          if (differenceInDays > 14) {
            cutAdmin = (0.05 * amount)+(platformPercentages.adminCut * 20000)+10000;
          } else {
            cutAdmin = (0.05 * amount) + (platformPercentages.adminCut * amount)+10000; // 10000 = $100
          }
          adminFirstTimeCut = 10000;
       }
      } else {
        if (differenceInDays > 14) {
          cutAdmin = (0.05 * amount)+(0.10 * 20000);
        } else {
          cutAdmin  = (0.05 * amount)+(0.10 * amount);
        }
      }

      const finalAmount = (parseInt(amount + taxTotal) + (0.05 * amount)) - (discount > 0 ? discount : 0);
      setAmountTotal(finalAmount);
      const renterData = await getCurrentUserDetails();
      try {
        let response;
        if (differenceInDays < 14) {
            response = await fetch(`${BASE_URL}/capture-payment`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              'Access-Control-Allow-Origin': `${BASE_URL}`,
            },
            mode: 'cors',
            body: JSON.stringify({
              tokenId: token.id,
              amount: finalAmount,
            }),
          });
        } else {
          response = await fetch(`${BASE_URL}/capture-payment-advance`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              'Access-Control-Allow-Origin': `${BASE_URL}`,
            },
            mode: 'cors',
            body: JSON.stringify({
              tokenId: token.id,
              amount: finalAmount,
              daysDifference: differenceInDays,
              paymentId: storedPaymentId,
              renter: bookingData.renter,
              owner: bookingData.owner,
              email: renterData.email,
              title: bookingData.listingTitle,
            }),
          });
        }
        const result = await response.json();
        console.log('result: ', result);

        if (result.success) {
          // Update the status to "Paid" in your database
          await updateBookingStatus(storedBookingId, 'Waiver Pending');

          const database = getDatabase();
  
          const paymentRef = ref(database, `payments/${storedPaymentId}`)
          let dbOwnerAmount = 0;
          
          if (differenceInDays > 14) {
            if(totalOwnerBookings > 0) {
              dbOwnerAmount = 20000 - cutAdmin + (0.05 * amount);
            }
          } else {
              dbOwnerAmount = amount - cutAdmin + (0.05 * amount);
          }

          try {
            let paymentData = {};
            paymentData = {
              totalAmount: finalAmount,
              ownerAmount: dbOwnerAmount,
              discount: discount > 0 ? discount : 0,
              cutPercentage,
              totalTax: taxTotal,
              adminCut: cutAdmin,
              adminTax: taxAdmin,
              dateTime: dayjs().format('dddd, MMMM D, YYYY HH:mm:ss'),
              transactionId: '0' || 'Upfront',
              userId: auth.currentUser.uid,
              bookingId: storedBookingId,
              owner: bookingData.owner
            };

            if (differenceInDays > 14) {
              paymentData.advanceBooking = 1;
              paymentData.advancePaid = 20000;
              paymentData.remainingAmount = (finalAmount - 20000);
            }
            
            if (totalOwnerBookings === 0) {
              paymentData.firstRentalPayment = 1;
            }
            
            if (refferalAmount > 0) {
              paymentData.referrer = referId;
              paymentData.referAmount = refferalAmount;
              paymentData.adminFirstTimeCut = adminFirstTimeCut;
            }
                        
              await update(paymentRef, paymentData);
              const subjectEmail = 'Payment Successful';
              const renterName = renterData.firstName + ' ' + renterData.lastName;
              const emailBody = await BookingStatusUpdateEmailBody (renterName, bookingData.listingTitle, 'Paid')
              sendEmail (renterData.email, subjectEmail, emailBody);

              const renterSmsBody = `Hello ${renterName}, your booking for ${bookingData.listingTitle} is now Paid!`
              const renterSmsResponse = sendSMS(renterData.phone, renterSmsBody);
              console.log(renterSmsResponse)

              const ownerData = await getUserByID(bookingData.owner);
              const subjectOwnerEmail = 'Payment Successful';
              const ownerName = ownerData.firstName + ' ' + ownerData.lastName;
              const emailOwnerBody = await BookingStatusUpdateEmailBody (ownerName, bookingData.listingTitle, 'Paid')
              sendEmail (ownerData.email, subjectOwnerEmail, emailOwnerBody);

              const ownerSmsBody = `Hello ${ownerName}, booking of ${bookingData.listingTitle} by ${renterName} is now Paid!`
              const ownerSmsResponse = sendSMS(renterData.phone, ownerSmsBody);
              console.log(ownerSmsResponse)

              const ownerMetaPayments = ref(database, `ownerMeta/${bookingData.owner}`)

              const previousOwnerPaymentsSnapshot = await get(ownerMetaPayments);

              if (previousOwnerPaymentsSnapshot.exists()) {

              const previousOwnerPayments = previousOwnerPaymentsSnapshot.val();

              const previousCurrentAmount = previousOwnerPayments.payments.currentAmount;
              const previousTotalAmount = previousOwnerPayments.payments.totalAmount;

              const ownerPayments = {
                currentAmount: previousCurrentAmount + dbOwnerAmount,
                totalAmount: previousTotalAmount + dbOwnerAmount,
              }
              console.log(ownerPayments)
              if(previousOwnerPayments.previousTotalAmount)  {
                const updatedTotalPaidBookings = parseInt(previousOwnerPayments.previousTotalAmount)+1;
                await update(ownerMetaPayments, {payments: ownerPayments, totalPaidBookings: updatedTotalPaidBookings});
              } else {
                await update(ownerMetaPayments, {payments: ownerPayments, totalPaidBookings: 1});
              }
            }
            else {
              const ownerPayments = {
                currentAmount:  dbOwnerAmount,
                totalAmount: dbOwnerAmount,
              }
              console.log(ownerPayments)
              await update(ownerMetaPayments, {payments: ownerPayments, totalPaidBookings: 1});
            }
              // await holdPayment();
            } catch (error) {
              console.log('Error saving data: ', error);
              setLoading(false);
            }
            navigate('/dashboard/thank-you');
            setLoading(false);
        }else if(result.error){
          console.error('Payment failed:', result.error);
          setError(result.error);
          setLoading(false);
        } else {
          console.error('Payment failed:');
          setError('Payment failed: ');
          setLoading(false);
        }
      } catch (error) {
        console.error('Error capturing payment:', error);
       setError(error);
        setLoading(false);
      }
    }
  };

  const holdPayment = async () => {
    if (!stripe || !elements) {
      return;
    }
  
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });
  
    if (error) {
      console.error(error);
    } else {
      try {
        const response = await fetch('https://www.bbrbassboatrentals.com/payment-hold', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            amount: 80000,
            payment_method: paymentMethod.id,
          }),
        });
  
        const result = await response.json();
        console.log('result: ', result);
  
        const database = getDatabase();
        const paymentRef = ref(database, `payments/${storedPaymentId}`);
  
        try {
          // Retrieve existing data
          const currentData = (await get(paymentRef)).val() || {};

          const paymentHeld = {
            id: result.paymentIntent.id,
            amount: result.paymentIntent.amount,
            status: 'pending',
          };
  
          // Merge existing data with new data
          const mergedData = {
            ...currentData,
            paymentHeld: paymentHeld,
          };
  
          // Update the database with merged data
          await update(paymentRef, mergedData);
        } catch (error) {
          console.log('Error saving data: ', error.message);
        }
      } catch (error) {
        console.error('Error capturing payment:', error);
      }
    }
  };
  

  const options = { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
  function formatDateTime(dateTimeString) {
    const formattedDateTimeNew = new Date(dateTimeString);
    return formattedDateTimeNew;
  }

  


  const offerPriceAsNumber = Number(offer.offerPrice);
  const offeredDate = formatDateTime(`${offer.offeredDate} ${offer.offeredTime}`);
  const amountDisplay = discount > 0 ? parseFloat(offerPriceAsNumber) + (0.05 * offerPriceAsNumber) + (parseFloat(platformPercentages.salesTax) * parseFloat(offerPriceAsNumber)) - parseFloat(discount/100).toLocaleString() : (parseFloat(offerPriceAsNumber) + (0.05 * offerPriceAsNumber) + parseFloat(platformPercentages.salesTax) * parseFloat(offerPriceAsNumber)).toLocaleString();
  const amountInteger = discount > 0 ? parseFloat(offerPriceAsNumber) + (parseFloat(platformPercentages.salesTax) * parseFloat(offerPriceAsNumber)) - parseFloat(discount/100).toLocaleString() : (parseFloat(offerPriceAsNumber) + parseFloat(platformPercentages.salesTax) * parseFloat(offerPriceAsNumber));
  // Convert the date string to a Date object

  // Get the current date
  var currentDate = new Date();

  // Calculate the difference in milliseconds
  var difference = offeredDate - currentDate;
  // Convert milliseconds to days
  var differenceInDays = difference / (1000 * 60 * 60 * 24);

  // Check if offerPriceAsNumber is a valid number
  const formattedOfferPrice = isNaN(offerPriceAsNumber)
    ? 'Invalid Number'
    : offerPriceAsNumber.toLocaleString();

  return (

    <>
    
    <div className="row">
        <div className="col">
          <div className="page-description d-flex justify-content-between">
            <h1>{bookingData.listingTitle}</h1>
          </div>
        </div>
        
    </div>


    <Row className='card p-4'>
    <div class="mb-4">
              <div class="mt-2">
                <div class="row">
                  <div class="col-sm-4">
                    <p class="mb-0">Amount</p>
                  </div>
                  <div class="col-sm-8">
                    {differenceInDays < 14 ? 
                      <h3 class="text-muted mb-0 text-dark">${amountDisplay} <span className="text-muted mb-0" style={{fontSize: '14px'}}>(includes 5% platform fees and 8.25% tax)</span></h3>
                      :
                      <>
                        <h3 class="text-muted mb-0 text-dark">$200 <span className="text-muted mb-0" style={{fontSize: '14px'}}>remaining ${(amountInteger-200)}(includes 5% platform fees and 8.25% tax) will be charged 48 hours prior start date.</span></h3>
                      </>
                    }
                    
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-sm-4">
                    <p class="mb-0">Offered Date Time</p>
                  </div>
                  <div class="col-sm-8">
                  <p className="text-muted mb-0">
                    {offeredDate.toLocaleString('en-US', options)}</p>
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-sm-4">
                    <p class="mb-0">Duration</p>
                  </div>
                  <div class="col-sm-8">
                    <p class="text-muted mb-0">{offer.duration}</p>
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-sm-4">
                    <p class="mb-0">Ariving Date Time</p>
                  </div>
                  <div class="col-sm-8">
                    <p class="text-muted mb-0">{offer.arivingDateTime}</p>
                  </div>
                </div>
              </div>
            </div>
      <Col>
        <form onSubmit={handleSubmit} className='mt-4'>
          <div style={{ marginBottom: '20px' }}>
            <Row>
              <Col>
                <TextField id="standard-basic" value={coupon} onChange={(e) => setCouponCode(e.target.value)} label="Coupon Code" variant="standard" />
                <Button variant="primary" onClick={() => verifyDiscount(coupon)} className='btn btn-primary m-1'>Apply</Button>
                {discount > 0 ? <Alert className='mt-3' severity="success">-${discount/100} discount has been applied.</Alert> : ''}
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                <CardElement
                  className="form-control"
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#424770',
                        '::placeholder': {
                          color: '#aab7c4',
                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }}
                />
                {error && <Alert className='mt-3' severity='error'>{error}</Alert>}
              </Col>
            </Row>
            <Row className='mt-4'>
              {/* <Col>
                <div className='d-flex mt-1 additional-box'>
                  <FormControlLabel style={{alignItems: 'flex-start'}} required control={<Checkbox checked={acknowledgement1} />} onChange={() => handleAcknowledgement('acknowledgement1')} />
                  <div>
                    <p style={{fontWeight: '500', lineHeight: '28px'}}>Please check this box to acknowledge that you will be responsible for a $800.00 refundable security fee <span style={{color: 'red'}}>*</span></p>
                    <p style={{fontStyle: 'italic', marginTop: '-11px'}}>Required for each rental transaction, returned within 7 days.</p>
                  </div>
                </div>

              </Col> */}
            </Row>
            <Row className='mt-4'>
              <Col>
                <div className='d-flex mt-1 additional-box'>
                  <FormControlLabel style={{alignItems: 'flex-start'}} required control={<Checkbox checked={acknowledgement2} />} onChange={setAcknowledgement2} />
                  <div>
                    <p style={{fontWeight: '500', lineHeight: '28px'}}>Please check this box to acknowledge that you have read and agreed to the cancellation policy - refer to the <Link to='/'>website</Link> for additional cancellation information <span style={{color: 'red'}}>*</span></p>
                  </div>
                </div>
                  

              </Col>
            </Row> 
          </div>
          <button variant="primary" type="submit" className='btn btn-primary m-1'>
            {loading ? 
            <ThreeDots
              visible={true}
              height="22"
              width="22"
              color="#fff"
              radius="20"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
              /> 
            : 
            'Pay'}
          </button>
        </form>
      </Col>
      
    </Row>
    </>
    
  );
};

export default PaymentForm;
