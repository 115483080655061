import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StartTrip from './StartTrip';


export default function StartTripWrapped() {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  return (
    <>
        <Elements stripe={stripePromise}>
            <StartTrip />
        </Elements>
    </>
  );
}
