import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import FloatingButtons from "./FloatingButtons";

export default function ClientRelationship() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <FloatingButtons />
      <section className="cs-bg-dark py-4 cs-section text-white privacy-policy">
        <div className="container">
          <Row className="mb-4">
            <Col>
              <Row className="mt-4">
                <Col>
                  <h1>How the platform works</h1>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <video
                    className="how-to-video"
                    controls
                    width="800"
                    playsinline
                  >
                    <source
                      src="https://bbrbassboatrentals.com/videos/BBR_EXPLAINER_VIDEO.mp4"
                      type="video/mp4"
                    ></source>
                  </video>
                </Col>
              </Row>
              {/* <Row className='mt-4'>
            <Col>
                <h4>How to send an inquiry.</h4>
                <p>Tutorial below shows how you can send an inquiry to a listing that you wish to rent.</p>
              <video className="how-to-video" controls width="800" playsinline>
                <source src="https://www.bbrbassboatrentals.com/videos/Send_Inquiry.mp4" type="video/mp4" />
              </video>
            </Col>
          </Row> */}
              {/* <Row className='mt-4'>
            <Col>
                <h4>How to create an offer.</h4>
                <p>The following tutorial is on how to create an offer after a renter has sent an inquiry for your listing.</p>
              <video className="how-to-video" controls width="800" playsinline>
                <source src="https://www.bbrbassboatrentals.com/videos/Create_Offer.mp4" type="video/mp4" />
              </video>
            </Col>
          </Row> */}
              {/* <Row className='mt-4'>
            <Col>
                <h4>How to view and accept an offer.</h4>
                <p>This is how you view the offer that you have received for your inquiry and accept it.</p>
              <video className="how-to-video" controls width="800" playsinline>
                <source src="https://www.bbrbassboatrentals.com/videos/View_Accept_Offer.mp4" type="video/mp4" />
              </video>
            </Col>
          </Row> */}
              {/* <Row className='mt-4'>
            <Col>
                <h4>How to list your boat.</h4>
                <p>How you list your boat for rent on the platform.</p>
              <video className="how-to-video" controls width="800" playsinline>
                <source src="https://www.bbrbassboatrentals.com/videos/List_Boat.mp4" type="video/mp4" />
              </video>
            </Col>
          </Row> */}
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
