// Payment.js
import DataTable from "../DataTable";
import { useAuth } from "../../contexts/AuthContext";
import { useEffect, useState } from "react";
import {
  getUserByID,
  getPaymentsByUserId,
  getListingByBookingId,
  getBoatOwnerPayments,
  getAllPayments,
  getCurrentUserDetails,
  getPaymentById,
} from "../utils";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import { Alert } from "@mui/material";
import {
  getDatabase,
  push,
  ref,
  get,
  update,
  serverTimestamp,
} from "firebase/database";
import { FaMoneyCheckAlt } from "react-icons/fa";

export default function Payments() {
  const Navigate = useNavigate();
  const auth = useAuth();
  const [formattedPayments, setFormattedPayments] = useState([]);
  const [currentBalance, setCurrentBalance] = useState([]);
  const [userDetails, setuserDetails] = useState([]);

  const [isRequestWithdrawalModalOpen, setRequestWithdrawalModalOpen] =
    useState(false);

  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const openRequestWithdrawalModal = () => {
    setRequestWithdrawalModalOpen(true);
  };

  const closeRequestWithdrawalModal = () => {
    setRequestWithdrawalModalOpen(false);
  };

  const handleRowClick = async (row) => {
    try {
      if (row && row.original && row.original.bookingId) {
        const bookingId = row.original.bookingId;
        const paymentData = await getPaymentById(row.original.id);
        console.log("paymentData");
        console.log(paymentData);
        if (paymentData.referrer === auth.currentUser.uid) {
          alert("Refferal Payment");
        } else {
          Navigate(`/dashboard/event/${bookingId}`);
        }
      } else {
        console.error("Invalid row object or missing bookingId");
      }
    } catch (error) {}
  };

  const sendWithdrawalRequest = async () => {
    try {
      const database = getDatabase();
      const withdrawalRef = ref(database, "withdrawals");

      const withdrawalRequest = {
        userId: auth.currentUser.uid,
        amount: withdrawalAmount,
        status: "Pending",
        timestamp: serverTimestamp(),
      };

      await push(withdrawalRef, withdrawalRequest);

      const ownerMetaRef = ref(database, `ownerMeta/${auth.currentUser.uid}`);

      // Retrieve current values
      const currentPaymentsSnapshot = await get(ownerMetaRef);
      const currentPayments = currentPaymentsSnapshot.val();

      // Update currentAmount while keeping totalAmount same
      const updatedPayments = {
        currentAmount:
          currentPayments.payments.currentAmount - withdrawalAmount * 100,
        totalAmount: currentPayments.payments.totalAmount,
      };

      // Use update instead of set to modify only the specified fields
      await update(ownerMetaRef, { payments: updatedPayments });

      setSuccess("Your withdrawal request has been submitted.");

      setCurrentBalance((prevBalance) => ({
        ...prevBalance,
        currentAmount:
          parseFloat(prevBalance.currentAmount) -
          parseFloat(withdrawalAmount * 100),
      }));

      setWithdrawalAmount(0);

      setTimeout(() => {
        setRequestWithdrawalModalOpen(false);
        setSuccess(false);
      }, 1000);
    } catch (error) {}
  };

  function getCurrentBalanceObject(paymentAmounts) {
    let balanceObject = null;

    if (Array.isArray(paymentAmounts) && paymentAmounts.length > 0) {
      for (let i = 0; i < paymentAmounts.length; i++) {
        if (
          typeof paymentAmounts[i] === "object" &&
          "currentAmount" in paymentAmounts[i] &&
          "totalAmount" in paymentAmounts[i]
        ) {
          balanceObject = paymentAmounts[i];
          break;
        }
      }
    }

    return balanceObject;
  }

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getCurrentUserDetails();
        setuserDetails(userData);

        let paymentDetails = [];

        paymentDetails = await getPaymentsByUserId(auth.currentUser.uid);
        if (userData.role === "Boat Owner") {
          const paymentAmounts = await getBoatOwnerPayments(
            auth.currentUser.uid
          );
          const paymentAmountFunction = getCurrentBalanceObject(paymentAmounts);
          console.log("paymentAmountFunction");
          console.log(paymentAmountFunction);
          setCurrentBalance(paymentAmountFunction);
        } else if (userData.role == "Admin") {
          paymentDetails = await getAllPayments();
        }
        if (paymentDetails != null) {
          const formattedData = paymentDetails.map(async (payment) => {
            if (payment.referrer === auth.currentUser.uid) {
              const ownerData = await getUserByID(payment.owner);
              return {
                id: payment.id,
                name: ownerData.firstName + " " + ownerData.lastName,
                dateTime: payment.dateTime,
                amount: "$" + parseFloat(payment.referAmount / 100),
                bookingId: payment.bookingId,
                // Add more properties as needed
              };
            }
            if (payment.reason === "Tip") {
              return {
                id: payment.id,
                name: "Tip",
                dateTime: payment.dateTime,
                amount: "$" + parseFloat(payment.ownerAmount / 100),
                bookingId: payment.bookingId,
                // Add more properties as needed
              };
            }
            let listingDetails;
            if (payment.bookingId) {
              listingDetails = await getListingByBookingId(payment.bookingId);
            }
            const user = await getUserByID(payment.userId);
           const renterName = user.firstName;
          //  const userOwner = await getUserByID(payment.owner);

          //  const owner = userOwner.firstName;
            return {
              id: payment.id,
              name:
                listingDetails && listingDetails.listingTitle
                  ? listingDetails.listingTitle
                  : "",
              dateTime: payment.dateTime,
              amount:
                "$" +
                (userData.role !== "Admin" && userData.role !== "Renter"
                  ? parseFloat(payment.ownerAmount / 100)
                  : parseFloat(payment.totalAmount / 100)),
              bookingId: payment.bookingId,
              renterName,
              // owner
              // Add more properties as needed
            };
          });

          Promise.all(formattedData).then((result) => {
            setFormattedPayments(result);
            console.log("result", result);
          });
        }
      } catch (error) {
        console.error("Error fetching payment details:", error);
      }
    };

    fetchUserData();
  }, [auth.currentUser.uid]);

  const columns = [
    { Header: "ID", accessor: "id" },
    { Header: "Name", accessor: "name" },
    { Header: "Renter Name", accessor: "renterName" },
    { Header: "Date Time", accessor: "dateTime" },
    { Header: "Amount", accessor: "amount" },
    { Header: "Booking ID", accessor: "bookingId" },
    // { Header: "Owner", accessor: "owner" },

    // Add more columns as needed
  ];

  // const data = [
  //   { name: 'John Doe', dateTime: '2023-01-15 10:30 AM', amount: 100.0, bookingId: 'B12345' },
  //   { name: 'Jane Doe', dateTime: '2023-02-02 03:45 PM', amount: 75.5, bookingId: 'B67890' },
  //   // Add more data rows as needed
  // ];

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Payments</h1>
          </div>
        </div>
      </div>

      {userDetails.role === "Boat Owner" ? (
        <Row>
          <Col xs={12} md={6}>
            <div class="card widget widget-bank-card">
              <div class="card-body">
                <div class="widget-bank-card-container widget-bank-card-mastercard d-flex flex-column">
                  <div class="card-logo">
                    <FaMoneyCheckAlt
                      style={{ width: "50px", height: "50px" }}
                    />
                  </div>
                  <span class="widget-bank-card-balance-title">
                    {userDetails.role === "Boat Owner" ? "CURRENT BALANCE" : ""}
                    {userDetails.role === "Admin" ? "Withdraw Requests" : ""}
                  </span>
                  <span class="widget-bank-card-balance">
                    {userDetails.role === "Boat Owner"
                      ? `${
                          currentBalance
                            ? "$" + currentBalance.currentAmount / 100
                            : 0
                        }`
                      : ""}

                    {userDetails.role === "Admin" ? "3 pending" : ""}
                  </span>
                  <span class="widget-bank-card-number mt-auto">
                    {userDetails.role === "Boat Owner" ? (
                      <Button
                        variant="primary"
                        onClick={openRequestWithdrawalModal}
                      >
                        Request Withdraw
                      </Button>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div class="card widget widget-bank-card">
              <div class="card-body">
                <div class="widget-bank-card-container widget-bank-card-visa d-flex flex-column">
                  <div class="card-logo">
                    <FaMoneyCheckAlt
                      style={{ width: "50px", height: "50px", color: "white" }}
                    />
                  </div>
                  <span class="widget-bank-card-balance-title">
                    {userDetails.role === "Boat Owner" ? "ALL PAYMENTS" : ""}
                    {userDetails.role === "Admin"
                      ? "TOTAL COLLECTABLE AMOUNT"
                      : ""}
                  </span>
                  <span class="widget-bank-card-balance">
                    ${currentBalance ? currentBalance.totalAmount / 100 : 0}
                  </span>
                  <span class="widget-bank-card-number mt-auto"></span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}

      <div className="row">
        <div className="col">
          <div className="card calendar-container">
            <div className="card-body payments-data-table">
              <DataTable
                columns={columns}
                data={formattedPayments ? formattedPayments : ""}
                onRowClick={handleRowClick}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        centered
        show={isRequestWithdrawalModalOpen}
        onHide={closeRequestWithdrawalModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Request Withdrawal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="priceInput">
            <Alert severity="info">
              Withdrawal requests may take several days to process.
            </Alert>
            <Form.Label className="mt-3">Amount to withdraw</Form.Label>
            <CurrencyInput
              placeholder="Enter Price"
              defaultValue={0}
              decimalsLimit={2}
              prefix="$"
              onValueChange={(value) => {
                // Set your maximum allowed value (e.g., $1000)
                const maxAllowedValue = currentBalance.currentAmount / 100;

                // Check if the entered value is within the allowed range
                if (value !== "") {
                  const numericValue = parseFloat(value);
                  if (!isNaN(numericValue) && numericValue <= maxAllowedValue) {
                    setWithdrawalAmount(value);
                    console.log(value);
                    setError("");
                  } else if (
                    !isNaN(numericValue) &&
                    numericValue >= maxAllowedValue
                  ) {
                    setError("Exceeded current balance");
                  } else {
                    setError("");
                    setWithdrawalAmount(0);
                  }
                } else {
                  // If the value is empty, set it to a minimum value or the last valid value
                  setWithdrawalAmount(0); // You can set it to a different minimum value if needed
                  setError("");
                }
              }}
              className="form-control"
            />

            {error && (
              <Alert className="mt-3" severity="error">
                {error}
              </Alert>
            )}
            <h6 className="mt-3" style={{ textAlign: "center" }}>
              Balance after withdrawal $
              {currentBalance
                ? currentBalance.currentAmount / 100 -
                  (withdrawalAmount > 0 ? withdrawalAmount : 0)
                : 0}
            </h6>
            {success && (
              <Alert className="mt-3" severity="success">
                {success}
              </Alert>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeRequestWithdrawalModal}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={withdrawalAmount <= 0}
            onClick={error ? "" : sendWithdrawalRequest}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
