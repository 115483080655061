import { Row, Col, Container } from 'react-bootstrap';
import FloatingButtons from './FloatingButtons';
import { useEffect } from 'react';
export default function FAQ() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  const faqData = [
    {
      "question": "Is there a fuel charge?",
      "answer": "That is a question best answered by the boat owner before starting your trip or upon making a booking inquiry. For all overnight bass boat rentals, it is expected for the renter to replace the fuel with the grade of gas specified by the boat owner. If you forget to replace the gas, the boat owner will have the option to hold the cost of replacing the gas, from your deposit. If it looks like you're going to run out of gas while on the water, fill up with the grade of gas specified by the boat owner. In all cases check with the boat owner before starting your trip."
    },
    {
      "question": "Who is allowed to drive the boat?",
      "answer": "The renter of the boat (the captain) may drive the boat. A BBR employee or trained operator may drive the boat with the permission of the boat owner; nobody else is allowed to drive the boat. It is the responsibility of the boat renter and captain to enforce this rule per the captain's signed liability waiver."
    },
    {
      "question": "What if more than one captain would like to drive the boat?",
      "answer": "Each captain will need to do the following:\n- Complete BBR renter verification waiver.\n- Complete a Captains waiver equipment checklist, listing the names of any other captains for the specific rental."
    },
    {
      "question": "Do I need to sign a liability waiver?",
      "answer": "The Captain and all passengers need to sign a digital waiver. There is a Captains waiver equipment checklist for the renter of the boat. Adult passengers need to complete an adult passenger waiver. Minors under the age of 18 need to have a parent or legal parental guardian complete a minor passenger waiver."
    },
    {
      "question": "When should I sign my waiver?",
      "answer": "The digital Captains waiver equipment checklist needs to be completed by the captain upon pick-up of the boat during the boat inspection. Upon acceptance of the equipment, the captain will sign the digital waiver; this triggers a timestamp that will start the rental period and place a deposit hold on the renter's credit card."
    },
    {
      "question": "When do I receive the keys to the boat?",
      "answer": "The Captain will receive the keys when all digital waivers are signed and the trip has started."
    },
    {
      "question": "What information do I need to have ready before I sign the captain's waiver?",
      "answer": "The Captains waiver requires that you list the names of all passengers."
    },
    {
      "question": "What if I want to add a passenger during the rental period?",
      "answer": "The renter needs to notify the boat owner by logging into their user dashboard inbox at www.bbrbassboatrentals.com. Both the renter and the boat owner have the ability to forward a waiver link to the added passenger. The added passenger or the parent or legal guardian for minors, needs to complete the digital passenger waiver. Following these rules will avoid any unexpected liability to the captain and/or the renter of the boat."
    },
    {
      "question": "Do the passengers that are minors (under 18 years of age) need to sign a liability waiver?",
      "answer": "The parent or legal guardian of a minor (under 18 years of age) needs to sign an electronic waiver on behalf of their minor before the rental period begins."
    },
    {
      "question": "Is there a weather-related cancellation policy?",
      "answer": "A boat owner is not obligated to cancel a reservation due to bad weather unless there is a named storm at the pick-up location. Cancellation of the booking is at the sole discretion of the boat owner. See cancellation policy."
    },
    {
      "question": "What is your cancellation policy?",
      "answer": "If cancellation occurs 2 weeks prior to your reservation, there will be a full refund. Within 2 weeks and up to 48 hours of the booking, we will keep a $200 booking fee from your refund amount. Cancellations within 48 hours prior to your reservation will be charged the full amount unless you reschedule to a date within 90 days of the original reservation. No shows for any reservation will be charged the full amount. In all cases, the boat owner has the option to waive any cancellation fees.\n\nIn the case of a bass boat rental, where a tournament is canceled prior to the reservation start \"by the tournament director,\" the boat owner can cancel and refund your booking at no cost. If the tournament is canceled on the day of the tournament \"by the tournament director,\" the boat owner will prorate your refund based on the daily rate. For example, if you return the boat 1 day early, the boat owner could refund the cost of that additional day rental. All refunded fees are guidelines and are negotiable between boat owner and renter. It is recommended that both parties be fair and considerate to maintain a good relationship for all future rental opportunities. BBR is not responsible for settling refund disputes between boat owner and renter."
    },
    {
      "question": "Are reservations required?",
      "answer": "Reservations are our business model! If you are in a tournament and need a tournament bass boat urgently due to a mechanical failure of your own vessel, our registered boat owners and operators will do everything possible to get you back on the water, if they have a boat available. We recommend if you are considering making a reservation, NOW is the time to create a login and get verified as an experienced bass boat captain. Once you are in the system, you will have immediate access to all of our listed boats."
    },
    {
      "question": "How old do I need to be to make a bass boat rental reservation?",
      "answer": "21 years old."
    },
    {
      "question": "How does BBR verify I can operate a bass boat?",
      "answer": "To become a verified bass boat operator, you need to complete renter verification. A BBR representative will verify the answers you provide. If you have experience operating a bass boat, you will likely have no problem getting approved. Sometimes BBR will request additional information to back up your answers. If you are unable to get verified, you will receive a denial. Although denied, you may still send a booking inquiry to a boat owner. In this case the decision is with the boat owner if they would like to proceed with the rental."
    },
    {
      "question": "Do I need a boater's license?",
      "answer": "In Texas for example, per TPWD regulation, if you were born on or after September 1, 1993, and are 18 or over, you must have completed a Boaters education course to legally rent a boat in Texas. However, BBR requires that you are at least 21 years old. For all rentals, it is the responsibility of the renter and boat owner to ensure they both follow the local regulations of the water body and the regulations of the city and state in which the rental will take place. Choose a boater's license course below:\n- https://www.boaterexam.com/\n- https://www.boat-ed.com/\n- https://www.boatsmartexam.com/us/texas/\n- https://www.boatus.org/\n- https://www.ilearntoboat.com/texas/"
    },
    {
      "question": "Is a deposit required to rent?",
      "answer": "Yes, all rentals require an $800 security deposit, which will be placed as a hold on the renter's credit card. The hold will be automatically released once the boat owner completes all checks, the boat owner has 7 days to complete checks after which time the hold will automatically expire. If any damage is identified by the boat owner, they will notify the renter of the cost of repair and may choose to use funds from the deposit to cover the cost. The boat owner is required to use their BBR dashboard messaging system to communicate the damages with receipts and pictures to the renter. If the renter's credit card does not have sufficient funds to accommodate the hold amount at the start of the rental, the owner may accept a cash deposit. It is recommended that both parties be fair and considerate to maintain a good relationship for all future rental opportunities. BBR is not responsible for settling disputes between boat owner and renter."
    },
    {
      "question": "Do I need insurance?",
      "answer": "For the duration of the rental period, or until the equipment is returned to the boat owner, whichever is longer, the Renter accepts responsibility for any damages caused to the rented equipment described in the rental agreement. The boat owner must have a liability insurance policy with a peer-to-peer boat rental addendum. That must be available to share with the renter at the renter's request. BBR does not have an insurance policy to provide coverage for any renters, their passengers, or for boat owners. Insurance companies such as State Farm do offer peer-to-peer boat rental policy addendums to personal watercraft owners with a personal watercraft insurance policy. It is recommended that boat owners get a peer-to-peer addendum for up to 30 rentals per year added to their policy, before they list their boat for rent. If the renter intends to fish a tournament, it is the renter's responsibility to check with the tournament director to ensure the insurance policy is acceptable to the tournament director prior to renting the boat. Typically a bass boat owner will need $300,000 of liability coverage to enter a tournament. The boat owner should provide proof of insurance directly to the tournament director upon request or share the policy details with the boat renter so they may present a copy to the tournament director. If you are uncertain of your own personal insurance coverage while renting a boat, we recommend you call your boat owner, homeowners, or home rental policy provider if you have one. If you do not have any insurance, the renter will be responsible. If trailering the boat, the renter is responsible for checking with the boat owner and also their own vehicle insurance policy provider to ensure they have adequate coverage."
    },
    {
      "question": "Does BBR sell supplemental insurance?",
      "answer": "No."
    },
    {
      "question": "What if I find a problem with the boat I have rented?",
      "answer": "The boat owner is obligated to do everything possible to ensure the boat, motor, prop, and trailer are fully functional and performing to the highest standard possible. If you notice a problem upon receiving the equipment, it is the renter's responsibility to advise the boat owner of any issues before signing off and accepting the equipment. It is the renter's responsibility to ensure they check everything fully before accepting the equipment using the Captains waiver equipment checklist. A walk-down with the boat owner or their representative is required, and the Captains waiver equipment checklist needs to be completed and signed by the renter before the trip can start and keys can be exchanged. If you have any questions at the time of the exchange, please feel free to ask questions to the boat owner to make you feel comfortable with the transaction at hand. If there is a problem with the equipment that renders it unusable to the renter and is not serviceable at the time of the start of the rental, the boat owner is obligated to endeavor to repair the equipment. If the repair or replacement is not to the satisfaction of the renter, the rental transaction will be canceled by the boat owner, including a full refund."
    },
    {
      "question": "What if I damage the boat I have rented?",
      "answer": "The renter has an obligation to advise the boat owner of the damage at the time damage occurs or as soon as it is noticed. The boat owner will do everything they can to fix the damage upon rental return. The boat owner may need to use the renter's deposit toward the repair costs. The boat owner will provide receipts for all work related to the repair of the damage. If the damage exceeds the amount of the deposit, the boat owner will either rely upon their insurance policy if it provides coverage under its terms, or will request the boat renter pay the additional expense. This would amount to the same circumstance if the renter were the owner of the vessel and needed to get it repaired themselves. It is recommended that both parties be fair and considerate to maintain a good relationship for all future rental opportunities. BBR is not responsible for settling disputes between boat owner and renter."
    },
    {
      "question": "Do you allow dogs on the boat?",
      "answer": "This is a question best answered by the boat owner before starting your trip or upon making a booking inquiry, to avoid any disappointment and to avoid any additional cleaning fees."
    },
    {
      "question": "Do I need to sign a waiver if the boat owner provides a captain?",
      "answer": "Yes, all passengers need to complete a digital waiver. Adult passengers 18 years or older need to complete an adult passenger waiver. Minors under the age of 18 need to have a parent or legal parental guardian complete a minor passenger waiver. The boat owner and renter has the ability to forward these digital waivers to all passengers through their BBR dashboard."
    }
  ];



  return (
    <>
    <FloatingButtons/>
    <section className='cs-bg-dark py-4 cs-section'>
        <Container>
            <Row>
                <Col>
                    <h1 className='text-white'>Frequently Asked Questions</h1>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col>
                    <div class="accordion" id="accordionExample">
                        {faqData.map((faq, index) => (
                          <div className="accordion-item" key={index}>
                            <h2 className="accordion-header" id={`heading${index}`}>
                              <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${index}`}
                                aria-controls={`collapse${index}`}
                              >
                                {faq.question}
                              </button>
                            </h2>
                            <div
                              id={`collapse${index}`}
                              className="accordion-collapse collapse"
                              aria-labelledby={`heading${index}`}
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <p dangerouslySetInnerHTML={{ __html: faq.answer.replace(/\n/g, '<br />') }}></p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
    </>
    
  )
}
